import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination,Autoplay } from "swiper";

import OurClientsData from "./OurClientsData";
import { Link } from "react-router-dom";


const OurClient = () => {
    return (
        <section className="portfolio-showcase-carosuel-wrapper fix section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-8 mb-4 offset-xl-3 offset-lg-2 col-12 text-center">
                        <div className="section-title-4">
                            <h2>Our Clients
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="portfolio-showcase-carousel-active">
                <Swiper pagination={false} modules={[Pagination,Autoplay]}
                    slidesPerView={5}
                    spaceBetween={15}
                    breakpoints={{
                        "@0.00": {
                            slidesPerView: 1,
                            spaceBetween: 20,
                            centeredSlides: true,

                        },
                        "@0.75": {
                            slidesPerView: 2,
                            spaceBetween: 20,
                            centeredSlides: true,

                        },
                        "@1.00": {
                            slidesPerView: 4,
                            spaceBetween: 20,

                        },
                        "@1.50": {
                            slidesPerView: 5,
                            spaceBetween: 20,
                        },
                    }}
                    speed={3000}
                    loop={true}
                        autoplay={{
                        delay: 500,
                        disableOnInteraction: false,
                    }}>
                    {
                        OurClientsData.map((data) => (
                            // <SwiperSlide className="single-recent-portfolio bg-cover" key={data.id} style={{backgroundImage: `url(${data.img})`}}>
                            //     <div className="project-details">
                            //         <h5><Link to="/projectDetails">{data.title}</Link></h5>
                            //         <span>{data.category}</span>
                            //         <Link to="/projectDetails" className='project-link'><i className="fal fa-chevron-right" /></Link>
                            //     </div>
                            // </SwiperSlide>

                            <SwiperSlide className="single-brand-logo " key={data.id}>
                                <div className='client-logo'>
                                    <img src={data.img} alt={data.title} />
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </section>
    )
}

export default OurClient;