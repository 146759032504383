import React from 'react'
import Header from '../Components/Header/Header'
import PageBanner from '../Components/PageBanner'
import bannerBg from '../assets/img/page-banner.jpg';
import Footer from '../Components/Footer/Footer';
import NewsWrapper from '../Components/News';

const News = () => {
  return (
    <div className="font-oswald">
      <Header/>
      <PageBanner title='News' bannerBg={bannerBg} currentPage='News' />
      <NewsWrapper/>
      <Footer/>
    </div>
  )
}

export default News