import { v4 as uuidv4 } from "uuid";
import fire_fighting_system from "../../assets/images/home/system/fire_fighting_system.webp";
import fire_fighting_system_01 from "../../assets/images/system/firefighting/acv.jpg";
import fire_fighting_system_02 from "../../assets/images/system/firefighting/diesel fire pump.jpg";
import fire_fighting_system_03 from "../../assets/images/system/firefighting/fire pump.jpg";

import water_less_fire_suppression_system from "../../assets/images/home/system/water_less_fire_suppression_system.webp";
import water_less_fire_suppression_system_01 from "../../assets/images/system/waterless/Aeroso System.jpg";
import water_less_fire_suppression_system_02 from "../../assets/images/system/waterless/Inert Gas.jpg";
import water_less_fire_suppression_system_03 from "../../assets/images/system/waterless/fm200.png";
import water_less_fire_suppression_system_04 from "../../assets/images/system/waterless/novec.jpg";

import water_based_fire_suppression_system from "../../assets/images/home/system/water_based_fire_suppression_system.webp";
import water_based_fire_suppression_system_01 from "../../assets/images/system/waterbased/Foam-System.jpg";
import water_based_fire_suppression_system_02 from "../../assets/images/system/waterbased/hifog-watermist-system.jpg";
import water_based_fire_suppression_system_03 from "../../assets/images/system/waterbased/pre action.jpg";

import fire_alarm_system from "../../assets/images/home/system/fire_alarm_system.webp";
import fire_alarm_system_01 from "../../assets/images/system/fire alarm/Fire-Alarm.jpg";
import fire_alarm_system_02 from "../../assets/images/system/fire alarm/fa1.jpg";
import fire_alarm_system_03 from "../../assets/images/system/fire alarm/fa2.jpg";

import ICT_and_ELV_Systems from "../../assets/images/home/system/ICT_and_ELV_Systems.webp";
import ICT_and_ELV_Systems_01 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_01.webp";
import ICT_and_ELV_Systems_02 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_02.webp";
import ICT_and_ELV_Systems_03 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_03.webp";
import ICT_and_ELV_Systems_04 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_04.webp";
import ICT_and_ELV_Systems_05 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_05.webp";
import ICT_and_ELV_Systems_06 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_06.webp";
import ICT_and_ELV_Systems_07 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_07.webp";
import ICT_and_ELV_Systems_08 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_08.webp";
import ICT_and_ELV_Systems_09 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_09.webp";
import ICT_and_ELV_Systems_10 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_10.webp";
import ICT_and_ELV_Systems_11 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_11.webp";
import ICT_and_ELV_Systems_12 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_12.webp";

import hvac_plumbing from "../../assets/images/home/system/hvac_plumbing.webp";
import environmental_solutions from "../../assets/images/home/system/environmental_solutions.webp";
import water_environmental_solutions from "../../assets/images/home/system/water_environmental_solutions.webp";
import environmental_solution_01 from "../../assets/images/system/environment systems/19.jpg";
import environmental_solution_02 from "../../assets/images/system/environment systems/18.JPG";
import environmental_solution_03 from "../../assets/images/system/environment systems/20.JPG";
import environmental_solution_04 from "../../assets/images/system/environment systems/21.JPG";
// import environmental_solution_05 from "../../assets/images/system/environment systems/22.JPG";

import water_waste_water_system_01 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/1.jpg";
import water_waste_water_system_02 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/2.jpg";
import water_waste_water_system_03 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/3.jpg";
import water_waste_water_system_04 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/4.jpg";
import water_waste_water_system_05 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/5.jpg";
import water_waste_water_system_06 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/6.jpg";
import water_waste_water_system_07 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/7.jpg";
import water_waste_water_system_08 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/8.jpg";
import water_waste_water_system_09 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/9.jpg";
import water_waste_water_system_10 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/10.jpg";
import water_waste_water_system_11 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/11.jpg";
import water_waste_water_system_12 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/12.jpg";
import water_waste_water_system_13 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/13.jpg";
import water_waste_water_system_14 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/14.jpg";

import hvac_01 from "../../assets/images/system/HVAC/1.jpg";
import hvac_02 from "../../assets/images/system/HVAC/2.jpg";
import hvac_03 from "../../assets/images/system/HVAC/3.jpg";
import hvac_04 from "../../assets/images/system/HVAC/4.jpg";
import hvac_05 from "../../assets/images/system/HVAC/5.jpg";
// import hvac_06 from "../../assets/images/system/HVAC/6.jpg"

import plumbing_01 from "../../assets/images/system/PLUMBIG/1.jpg";
import plumbing_02 from "../../assets/images/system/PLUMBIG/2.jpg";
import plumbing_03 from "../../assets/images/system/PLUMBIG/3.jpg";

import ff_valves_and_accessories_01 from "../../assets/images/system/FF VALVES & ACCESSORIES/1.jpg";
import ff_valves_and_accessories_02 from "../../assets/images/system/FF VALVES & ACCESSORIES/2.jpg";
import ff_valves_and_accessories_03 from "../../assets/images/system/FF VALVES & ACCESSORIES/3.jpg";
import ff_valves_and_accessories_04 from "../../assets/images/system/FF VALVES & ACCESSORIES/4.jpg";
import ff_valves_and_accessories_05 from "../../assets/images/system/FF VALVES & ACCESSORIES/5.jpg";
import ff_valves_and_accessories_06 from "../../assets/images/system/FF VALVES & ACCESSORIES/6.jpg";
import ff_valves_and_accessories_07 from "../../assets/images/system/FF VALVES & ACCESSORIES/7.jpg";
import ff_valves_and_accessories_08 from "../../assets/images/system/FF VALVES & ACCESSORIES/8.jpg";
import ff_valves_and_accessories_09 from "../../assets/images/system/FF VALVES & ACCESSORIES/9.jpg";
import ff_valves_and_accessories_10 from "../../assets/images/system/FF VALVES & ACCESSORIES/10.jpg";

import fire_suppression_system_01 from "../../assets/images/system/FIRE SUPPRESSION SYSTEMS/1.png";
import fire_suppression_system_02 from "../../assets/images/system/FIRE SUPPRESSION SYSTEMS/2.png";
import fire_suppression_system_03 from "../../assets/images/system/FIRE SUPPRESSION SYSTEMS/3.png";
import fire_suppression_system_04 from "../../assets/images/system/FIRE SUPPRESSION SYSTEMS/4.png";
import fire_suppression_system_05 from "../../assets/images/system/FIRE SUPPRESSION SYSTEMS/5.png";


const servicesImages = [
  {
    id: uuidv4(),
    img: fire_fighting_system_02,
    title: "Water Based Fire Fighting System",
  },
  {
    id: uuidv4(),
    img: fire_fighting_system_01,
    title: "Water Based Fire Fighting System",
  },
  {
    id: uuidv4(),
    img: fire_fighting_system_03,
    title: "Water Based Fire Fighting System",
  },
  {
    id: uuidv4(),
    img: fire_fighting_system,
    title: "Water Based Fire Fighting System",
  },

  {
    id: uuidv4(),
    img: water_less_fire_suppression_system_01,
    title: "Water Less Fire Suppression System",
  },
  {
    id: uuidv4(),
    img: water_less_fire_suppression_system_02,
    title: "Water Less Fire Suppression System",
  },
  {
    id: uuidv4(),
    img: water_less_fire_suppression_system_03,
    title: "Water Less Fire Suppression System",
  },
  {
    id: uuidv4(),
    img: water_less_fire_suppression_system_04,
    title: "Water Less Fire Suppression System",
  },
  {
    id: uuidv4(),
    img: water_less_fire_suppression_system,
    title: "Water Less Fire Suppression System",
  },

  {
    id: uuidv4(),
    img: water_based_fire_suppression_system_01,
    title: "Water Based Fire Suppression System",
  },
  {
    id: uuidv4(),
    img: water_based_fire_suppression_system_02,
    title: "Water Based Fire Suppression System",
  },
  {
    id: uuidv4(),
    img: water_based_fire_suppression_system_03,
    title: "Water Based Fire Suppression System",
  },
  {
    id: uuidv4(),
    img: water_based_fire_suppression_system,
    title: "Water Based Fire Suppression System",
  },

  {
    id: uuidv4(),
    img: ICT_and_ELV_Systems_01,
    title: "ICT and ELV Systems",
  },
  {
    id: uuidv4(),
    img: ICT_and_ELV_Systems_02,
    title: "ICT and ELV Systems",
  },
  {
    id: uuidv4(),
    img: ICT_and_ELV_Systems_03,
    title: "ICT and ELV Systems",
  },
  {
    id: uuidv4(),
    img: ICT_and_ELV_Systems_04,
    title: "ICT and ELV Systems",
  },
  {
    id: uuidv4(),
    img: ICT_and_ELV_Systems_05,
    title: "ICT and ELV Systems",
  },
  {
    id: uuidv4(),
    img: ICT_and_ELV_Systems_06,
    title: "ICT and ELV Systems",
  },
  {
    id: uuidv4(),
    img: ICT_and_ELV_Systems_07,
    title: "ICT and ELV Systems",
  },
  {
    id: uuidv4(),
    img: ICT_and_ELV_Systems_08,
    title: "ICT and ELV Systems",
  },
  {
    id: uuidv4(),
    img: ICT_and_ELV_Systems_09,
    title: "ICT and ELV Systems",
  },
  {
    id: uuidv4(),
    img: ICT_and_ELV_Systems_10,
    title: "ICT and ELV Systems",
  },
  {
    id: uuidv4(),
    img: ICT_and_ELV_Systems_11,
    title: "ICT and ELV Systems",
  },
  {
    id: uuidv4(),
    img: ICT_and_ELV_Systems_12,
    title: "ICT and ELV Systems",
  },

  {
    id: uuidv4(),
    img: hvac_01,
    title: "HVAC & Plumbing",
  },
  {
    id: uuidv4(),
    img: hvac_02,
    title: "HVAC & Plumbing",
  },
  {
    id: uuidv4(),
    img: hvac_03,
    title: "HVAC & Plumbing",
  },
  {
    id: uuidv4(),
    img: hvac_04,
    title: "HVAC & Plumbing",
  },
  {
    id: uuidv4(),
    img: hvac_05,
    title: "HVAC & Plumbing",
  },
  {
    id: uuidv4(),
    img: plumbing_01,
    title: "HVAC & Plumbing",
  },
  {
    id: uuidv4(),
    img: plumbing_02,
    title: "HVAC & Plumbing",
  },
  {
    id: uuidv4(),
    img: plumbing_03,
    title: "HVAC & Plumbing",
  },
  {
    id: uuidv4(),
    img: hvac_plumbing,
    title: "HVAC & Plumbing",
  },

  {
    id: uuidv4(),
    img: environmental_solution_02,
    title: "Water & Environmental Solutions",
  },
  {
    id: uuidv4(),
    img: environmental_solution_03,
    title: "Water & Environmental Solutions",
  },
  {
    id: uuidv4(),
    img: environmental_solution_04,
    title: "Water & Environmental Solutions",
  },
  {
    id: uuidv4(),
    img: water_waste_water_system_03,
    title: "Water & Environmental Solutions",
  },
  {
    id: uuidv4(),
    img: water_waste_water_system_04,
    title: "Water & Environmental Solutions",
  },
  {
    id: uuidv4(),
    img: water_waste_water_system_08,
    title: "Water & Environmental Solutions",
  },
  {
    id: uuidv4(),
    img: water_waste_water_system_09,
    title: "Water & Environmental Solutions",
  },
  {
    id: uuidv4(),
    img: water_waste_water_system_10,
    title: "Water & Environmental Solutions",
  },
  {
    id: uuidv4(),
    img: water_waste_water_system_11,
    title: "Water & Environmental Solutions",
  },
  {
    id: uuidv4(),
    img: water_waste_water_system_12,
    title: "Water & Environmental Solutions",
  },
  {
    id: uuidv4(),
    img: water_waste_water_system_13,
    title: "Water & Environmental Solutions",
  },
  {
    id: uuidv4(),
    img: water_waste_water_system_14,
    title: "Water & Environmental Solutions",
  },
  {
    id: uuidv4(),
    img: environmental_solution_01,
    title: "Water & Environmental Solutions",
  },
];

export default servicesImages;
