import React from 'react'
import Footer from '../Components/Footer/Footer';
import Header from '../Components/Header/Header';
import Details from '../Components/News/Details';
import PageBanner from '../Components/PageBanner/index';
import bannerBg from '../assets/img/page-banner.jpg';

const NewsDetails = () => {
  return (
    <div className="font-oswald">
        <Header/>
        <PageBanner title='News' bannerBg={bannerBg} currentPage='News Details' />
        <Details/>
        <Footer/>
    </div>
  )
}

export default NewsDetails