import React from 'react'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import bannerBg from '../assets/img/page-banner.jpg';
import PageBanner from '../Components/PageBanner';
import ClientCarousel from "../Components/Brands/ClientCarousel";
import Pricing from "../Components/Price/Pricing";
import TestimonialOne from '../Components/Testimonial/TestimonialOne';

const PricingPage = () => {
  return (
    <div className="font-oswald">
        <Header/>
        <PageBanner title='Plan Price' bannerBg={bannerBg} currentPage='price' />
        <Pricing/>
        <TestimonialOne/>
        <ClientCarousel/>
        <Footer/>
    </div>
  )
}

export default PricingPage