import videoBg from "../../assets/img/video_bg_1.jpg";
import imgBlock1 from "../../assets/images/about/about-01-01.jpg";
import tabImg1 from "../../assets/img/home1/tab-img.jpg";
import { Link } from "react-router-dom";
import { useState } from "react";
import "react-modal-video/scss/modal-video.scss";
import ModalVideo from "react-modal-video";
import ServiceCarousel from "../Services/ServiceCarousel";
import servicesData from "../Services/servicesHomeData";

const OurBusiness = ({ pt }) => {
  return (
    <>
      <div className="block-contents">
        <div className="section-title">
          {/* <span>01. About Company</span> */}
          <h2>Our Business</h2>
        </div>
        <p>
          {" "}
          FireDOC is an energetic group of vastly experienced professionals. Our
          specializations and expertise in the Fire Protection, ICT (Information
          & Communication Technology), ELV (Extra Low Voltage), HVAC, Plumbing &
          Drainage and Environmental Engineering fields enables us in clearly
          understanding customer requirements, designing the perfect system,
          identifying the best & cost efficient products and services, ensuring
          the quality in installations and assuring the performance of the
          systems to the customer’s highest satisfaction.
        </p>
        <p>
          Our technically qualified and competent team provide comprehensive
          services right from project planning and tendering till the final
          handing over for a broad spectrum of sectors including Oil & Gas,
          Industrial, Infrastructural, Commercial and Residential projects
        </p>
      </div>
      <div className="tab-content-block">
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="pills-technology-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-technology"
              type="button"
              role="tab"
              aria-controls="pills-technology"
              aria-selected="true"
            >
              Systems
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-vision-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-vision"
              type="button"
              role="tab"
              aria-controls="pills-vision"
              aria-selected="false"
            >
              Vision
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-mission-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-mission"
              type="button"
              role="tab"
              aria-controls="pills-mission"
              aria-selected="false"
            >
              Mission
            </button>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-technology"
            role="tabpanel"
            aria-labelledby="pills-technology-tab"
          >
            <div className="tab-inner-contents">
              <ServiceCarousel></ServiceCarousel>
              <div className="checked-features-list">
                <ul>
                  {servicesData.map((data) => (
                    <li>
                      <Link key={data.id} to={data.link_para}>
                        {data.title.toUpperCase()}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-vision"
            role="tabpanel"
            aria-labelledby="pills-vision-tab"
          >
            <div className="tab-inner-contents">
              <div className="checked-features-list">
                <p>
                  FireDOC aspire to be the prime choice of our customers when it
                  comes Fire Protection ICT (Information & Communication
                  Technology), ELV (Extra Low Voltage), HVAC, Plumbing &
                  Drainage and Environmental products & solutions. Our Aim is to
                  provide the best in industry Engineering support and Quality
                  products & services to exceed the expectations of our clients
                </p>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-mission"
            role="tabpanel"
            aria-labelledby="pills-mission-tab"
          >
            <div className="tab-inner-contents">
              <div className="checked-features-list">
                <p>
                  FireDOC strive to propose the optimal solution to our clients
                  by clearly analyzing and understanding the requirements
                  through truthful and transparent communications. The peace of
                  mind and satisfaction of the end user for having an effective
                  and functional system is our primary objective.
                </p>
                <br></br>
                <p>
                  Our Employees are our greatest asset and we give utmost
                  importance to the knowledge level and personality traits of
                  our team. Continuous learning and development policy is being
                  implemented to keep our team abreast with the latest
                  development in the field of their activity
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurBusiness;
