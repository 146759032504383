import React from 'react'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import bannerBg from '../assets/img/page-banner.jpg';
import PageBanner from '../Components/PageBanner';
import TeamOne from "../Components/Team/TeamOne";
import TeamTwo from "../Components/Team/TeamTwo";

const TeamPage = () => {
  return (
    <div className="font-oswald">
        <Header/>
        <PageBanner title='Our Team' bannerBg={bannerBg} currentPage='Team' />
        <TeamTwo/>
        <TeamOne/>
        <Footer/>
    </div>
  )
}

export default TeamPage