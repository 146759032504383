import { Swiper, SwiperSlide } from "swiper/react";
import slide2 from "../../assets/images/hero/home_slide_1.webp";
import slide1 from "../../assets/images/hero/home_slide_2.webp";
import slide5 from "../../assets/images/hero/home_slide_4.jpg";
import slide3 from "../../assets/images/home/third_party_project_documentation.webp";
import slide4 from "../../assets/images/system/ICT_and_ELV/ICT.jpg";

import { Autoplay, EffectFade } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import "swiper/css/effect-fade";

const Hero = () => {
  return (
    <section className="hero-wrapper hero-1">
      <Swiper
        navigation={true}
        modules={[Autoplay, Navigation, EffectFade]}
        effect={"fade"}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide className="hero-slider-2">
          <Hero_Home_Swiper slide_img={slide5}></Hero_Home_Swiper>
        </SwiperSlide>
        <SwiperSlide className="hero-slider-2">
          <Hero_Home_Swiper slide_img={slide1}></Hero_Home_Swiper>
        </SwiperSlide>
        <SwiperSlide className="hero-slider-2">
          <Hero_Home_Swiper slide_img={slide3}></Hero_Home_Swiper>
        </SwiperSlide>
        <SwiperSlide className="hero-slider-2">
          <Hero_Home_Swiper slide_img={slide2}></Hero_Home_Swiper>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Hero;

const Hero_Home_Swiper = ({slide_img}) => {
  var home_title = "Your Trusted Partner in Comprehensive Fire, Safety, and Smart Technology Solutions";
  var home_dec =
    "Empowering Safety, Engineering Excellence: Your Trusted Partner in Fire Protection, ELV, ICT, HVAC, Plumbing & Drainage, and Environmental Solutions.";

  return (
    <div
      className="single-slide bg-cover"
      style={{ backgroundImage: `url(${slide_img})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 ">
            <div className="hero-contents">
              <h1 className="wow fadeInLeft animated " data-wow-duration="1.3s">
                {home_title}
              </h1>
              <p
                className="pe-lg-5 wow fadeInLeft animated"
                data-wow-duration="1.3s"
                data-wow-delay=".4s"
              >
                {home_dec}
              </p>
              <Link
                to="/contact"
                className="theme-btn me-sm-4 wow fadeInLeft"
                data-wow-duration="1.2s"
                data-wow-delay=".8s"
              >
                contact us
              </Link>
              <Link
                to="/about"
                className="theme-btn wow fadeInLeft"
                data-wow-duration="1.2s"
                data-wow-delay=".6s"
              >
                our portfolio
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
