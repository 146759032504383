import { v4 as uuidv4 } from "uuid";

import third_party_project_documentation from "../../assets/images/home/third_party_project_documentation.webp";

import fire_fighting_system from "../../assets/images/home/system/fire_fighting_system.webp";
import fire_fighting_system_01 from "../../assets/images/system/firefighting/acv.jpg";
import fire_fighting_system_02 from "../../assets/images/system/firefighting/diesel fire pump.jpg";
import fire_fighting_system_03 from "../../assets/images/system/firefighting/fire pump.jpg";

import water_less_fire_suppression_system from "../../assets/images/home/system/water_less_fire_suppression_system.webp";
import water_less_fire_suppression_system_01 from "../../assets/images/system/waterless/Aeroso System.jpg";
import water_less_fire_suppression_system_02 from "../../assets/images/system/waterless/Inert Gas.jpg";
import water_less_fire_suppression_system_03 from "../../assets/images/system/waterless/fm200.png";
import water_less_fire_suppression_system_04 from "../../assets/images/system/waterless/novec.jpg";

import water_based_fire_suppression_system from "../../assets/images/home/system/water_based_fire_suppression_system.webp";
import water_based_fire_suppression_system_01 from "../../assets/images/system/waterbased/Foam-System.jpg";
import water_based_fire_suppression_system_02 from "../../assets/images/system/waterbased/hifog-watermist-system.jpg";
import water_based_fire_suppression_system_03 from "../../assets/images/system/waterbased/pre action.jpg";

import fire_alarm_system from "../../assets/images/home/system/fire_alarm_system.webp";
import fire_alarm_system_01 from "../../assets/images/system/fire alarm/Fire-Alarm.jpg";
import fire_alarm_system_02 from "../../assets/images/system/fire alarm/fa1.jpg";
import fire_alarm_system_03 from "../../assets/images/system/fire alarm/fa2.jpg";

import ICT_and_ELV_Systems from "../../assets/images/home/system/ICT_and_ELV_Systems.webp";
import ICT_and_ELV_Systems_01 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_01.webp";
import ICT_and_ELV_Systems_02 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_02.webp";
import ICT_and_ELV_Systems_03 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_03.webp";
import ICT_and_ELV_Systems_04 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_04.webp";
import ICT_and_ELV_Systems_05 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_05.webp";
import ICT_and_ELV_Systems_06 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_06.webp";
import ICT_and_ELV_Systems_07 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_07.webp";
import ICT_and_ELV_Systems_08 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_08.webp";
import ICT_and_ELV_Systems_09 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_09.webp";
import ICT_and_ELV_Systems_10 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_10.webp";
import ICT_and_ELV_Systems_11 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_11.webp";
import ICT_and_ELV_Systems_12 from "../../assets/images/system/ICT_and_ELV/ICT_and_ELV_12.webp";
import ICT_and_ELV_Systems_13 from "../../assets/images/system/ICT_and_ELV/ICT.jpg";

import hvac_plumbing from "../../assets/images/home/system/hvac_plumbing.webp";
import environmental_solutions from "../../assets/images/home/system/environmental_solutions.webp";
import water_environmental_solutions from "../../assets/images/home/system/water_environmental_solutions.webp";
import environmental_solution_01 from "../../assets/images/system/environment systems/19.jpg";
import environmental_solution_02 from "../../assets/images/system/environment systems/18.JPG";
import environmental_solution_03 from "../../assets/images/system/environment systems/20.JPG";
import environmental_solution_04 from "../../assets/images/system/environment systems/21.JPG";
// import environmental_solution_05 from "../../assets/images/system/environment systems/22.JPG";

import water_waste_water_system_01 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/1.jpg";
import water_waste_water_system_02 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/2.jpg";
import water_waste_water_system_03 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/3.jpg";
import water_waste_water_system_04 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/4.jpg";
import water_waste_water_system_05 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/5.jpg";
import water_waste_water_system_06 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/6.jpg";
import water_waste_water_system_07 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/7.jpg";
import water_waste_water_system_08 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/8.jpg";
import water_waste_water_system_09 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/9.jpg";
import water_waste_water_system_10 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/10.jpg";
import water_waste_water_system_11 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/11.jpg";
import water_waste_water_system_12 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/12.jpg";
import water_waste_water_system_13 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/13.jpg";
import water_waste_water_system_14 from "../../assets/images/system/WATER & WATE WATER SYSTEMS/14.jpg";

import hvac_01 from "../../assets/images/system/HVAC/1.jpg";
import hvac_02 from "../../assets/images/system/HVAC/2.jpg";
import hvac_03 from "../../assets/images/system/HVAC/3.jpg";
import hvac_04 from "../../assets/images/system/HVAC/4.jpg";
import hvac_05 from "../../assets/images/system/HVAC/5.jpg";
// import hvac_06 from "../../assets/images/system/HVAC/6.jpg"

import plumbing_01 from "../../assets/images/system/PLUMBIG/1.jpg";
import plumbing_02 from "../../assets/images/system/PLUMBIG/2.jpg";
import plumbing_03 from "../../assets/images/system/PLUMBIG/3.jpg";

import ff_valves_and_accessories_01 from "../../assets/images/system/FF VALVES & ACCESSORIES/1.jpg";
import ff_valves_and_accessories_02 from "../../assets/images/system/FF VALVES & ACCESSORIES/2.jpg";
import ff_valves_and_accessories_03 from "../../assets/images/system/FF VALVES & ACCESSORIES/3.jpg";
import ff_valves_and_accessories_04 from "../../assets/images/system/FF VALVES & ACCESSORIES/4.jpg";
import ff_valves_and_accessories_05 from "../../assets/images/system/FF VALVES & ACCESSORIES/5.jpg";
import ff_valves_and_accessories_06 from "../../assets/images/system/FF VALVES & ACCESSORIES/6.jpg";
import ff_valves_and_accessories_07 from "../../assets/images/system/FF VALVES & ACCESSORIES/7.jpg";
import ff_valves_and_accessories_08 from "../../assets/images/system/FF VALVES & ACCESSORIES/8.jpg";
import ff_valves_and_accessories_09 from "../../assets/images/system/FF VALVES & ACCESSORIES/9.jpg";
import ff_valves_and_accessories_10 from "../../assets/images/system/FF VALVES & ACCESSORIES/10.jpg";

import fire_suppression_system_01 from "../../assets/images/system/FIRE SUPPRESSION SYSTEMS/1.png";
import fire_suppression_system_02 from "../../assets/images/system/FIRE SUPPRESSION SYSTEMS/2.png";
import fire_suppression_system_03 from "../../assets/images/system/FIRE SUPPRESSION SYSTEMS/3.png";
import fire_suppression_system_04 from "../../assets/images/system/FIRE SUPPRESSION SYSTEMS/4.png";

import passive_fire_protection_01 from "../../assets/images/system/passive_fire_protection/passive_fire_protection_01.jpg";

const servicesHomeData = [
  {
    id: uuidv4(),
    icon: "icon-brainstorming",
    img: third_party_project_documentation,
    link_para: "/servicesDetails/eng_and_construction_services",
    title: "Engineering and Construction Services",
    desc: "",
    services: [
      "Tender Support",
      "Engineering Design: Schematic And Detailed Design",
      "Construction Document Support",
      "Closeout Documentation",
      "Epc Of Fire Protection Systems",
      "Fitout Tasks For Ict/Elv Systems",
      "Manpower Supply For Engineering Services",
    ],
    servicesImg: [],
    servicesDec: [
      {
        id: uuidv4(),
        title: "Tender Support",
        desc: [
          "Pre-Qualification documents, technical proposals, and Bill of Quantities/Bill of Materials (BoQ/BoM)",
          "Expert guidance in system design/selection to meet project requirements",
        ],
      },
      {
        id: uuidv4(),
        title: "Engineering Design: Schematic and Detailed Design",
        desc: [
          "Development of schematic and detailed designs from concept design, in compliance with industry standards and regulations (TIA/IEC/BICSI/NFPA/MOI).",
          "Conducting analytical studies for system efficiency and reliability.",
        ],
      },
      {
        id: uuidv4(),
        title: "Construction Document Support",
        desc: [
          "Preparation of material approval submittals, shop drawings, and Inspection & Test Plans (ITPs).",
          "Comprehensive testing and commissioning procedures to ensure optimal system performance.",
          "BIM / REVIT / AUTOCAD drawings preparation and clash detection.",
        ],
      },
      {
        id: uuidv4(),
        title: "Closeout Documentation",
        desc: [
          "Preparation of Operation & Maintenance (O&M) manuals, as-built drawings, and final project handover documents.",
        ],
      },
      {
        id: uuidv4(),
        title: "EPC of Fire Protection Systems",
        desc: [
          "Design and installation of fire alarm, fire extinguishing, and fire-fighting systems to ensure safety & compliance.",
        ],
      },
      {
        id: uuidv4(),
        title: "Fitout Tasks for ICT/ELV Systems",
        desc: [
          "Complete fitout services ensuring quality installation and seamless system integration.",
        ],
      },
      {
        id: uuidv4(),
        title: "Manpower Supply for Engineering Services",
        desc: [
          "Provide skilled engineers, project managers, and specialists to support project requirements.",
        ],
      },
      {
        id: uuidv4(),
        title: "Passive Fire Protection",
        desc: ["Fire & Smoke Curtains", "Fire Stopping & Fire Sealants"],
      },
    ],
    showServicesDec: true,
    showServicesImg: false,
    showInHome: true,
  },
  {
    id: uuidv4(),
    icon: "fa-yandex-international",
    img: fire_fighting_system,
    link_para: "/servicesDetails/fire_fighting_system",
    title: "Water Based Fire Fighting System",
    desc: "",
    services: [
      "Sprinklers",
      "Valves & Accessories",
      "Fire Pump",
      "Fire Hose Reels",
      "Fire Extinguishers",
      // "Fire Fighting Monitors"
    ],
    servicesImg: [
      fire_fighting_system_02,
      fire_fighting_system_01,
      fire_fighting_system_03,
      fire_fighting_system,
    ],
    servicesDec: [],
    showServicesDec: false,
    showServicesImg: true,
    showInHome: true,
  },

  {
    id: uuidv4(),
    icon: "icon-bullhorn",
    img: water_less_fire_suppression_system,
    link_para: "/servicesDetails/water_less_fire_suppression_system",
    title: "Water Less Fire Suppression System",
    desc: "",
    services: [
      "FM200 System",
      "Novec 1230 System",
      "Inert Gas Systems",
      "Kitchen Hood Fire Suppression Systems",
      "Aerosol System",
    ],
    servicesImg: [
      water_less_fire_suppression_system_01,
      water_less_fire_suppression_system_02,
      water_less_fire_suppression_system_03,
      water_less_fire_suppression_system_04,
      water_less_fire_suppression_system,

      // fire_suppression_system_01,
      // fire_suppression_system_02,
      // fire_suppression_system_03,
      // fire_suppression_system_04,
      // // fire_suppression_system_05,
    ],
    servicesDec: [],
    showServicesDec: false,
    showServicesImg: true,
    showInHome: true,
  },

  {
    id: uuidv4(),
    icon: "icon-writing",
    img: water_based_fire_suppression_system,
    link_para: "/servicesDetails/water_based_fire_suppression_system",
    title: "Water Based Fire Suppression System",
    desc: "",
    services: [
      "Foam System",
      "Water Deluge System",
      "Preaction Systems",
      "Water Mist Systems",
      "Fire Fighting Monitors",
    ],
    servicesImg: [
      water_based_fire_suppression_system_01,
      water_based_fire_suppression_system_02,
      water_based_fire_suppression_system_03,
      water_based_fire_suppression_system,
    ],
    servicesDec: [],
    showServicesDec: false,
    showServicesImg: true,
    showInHome: true,
  },
  // {
  //     id: uuidv4(),
  //     icon: 'icon-curve',
  //     img: fire_alarm_system,
  //     link_para: "/servicesDetails/fire_alarm_system",
  //     title: 'Fire Alarm System',
  //     desc: '',
  //     services: [
  //         "Conventional Panel & Devices",
  //         "Addressable Panel & Devices",
  //         "PA/VA Systems",
  //         "Emergency & Exit Lights",
  //         "Notification Devices",
  //     ],
  //     servicesImg: [
  //         fire_alarm_system_01,
  //         fire_alarm_system_02,
  //         fire_alarm_system_03,
  //         fire_alarm_system
  //     ]
  // },

  {
    id: uuidv4(),
    icon: "icon-curve",
    img: ICT_and_ELV_Systems_13,
    link_para: "/servicesDetails/ICT_and_ELV_Systems",
    title: "ICT and ELV Systems",
    desc: "",
    services: [
      "Conventional & Addressable Fire Alarm Systems",
      "Inside Plant & Outside Plant Cabling",
      "Lan & Wireless Lan Systems",
      "Security System Including CCTV, Access Control, Gate Barriers Road Blockers",
      "Audio Visual System Including Public Address and IPTV Systems",
      "Guest Room Management Systems",
      "Building Management Systems",
    ],
    servicesImg: [
      ICT_and_ELV_Systems_01,
      ICT_and_ELV_Systems_02,
      ICT_and_ELV_Systems_03,
      ICT_and_ELV_Systems_04,
      ICT_and_ELV_Systems_05,
      ICT_and_ELV_Systems_06,
      ICT_and_ELV_Systems_07,
      ICT_and_ELV_Systems_08,
      ICT_and_ELV_Systems_09,
      ICT_and_ELV_Systems_10,
      ICT_and_ELV_Systems_11,
      ICT_and_ELV_Systems_12,
    ],
    servicesDec: [],
    showServicesDec: false,
    showServicesImg: true,
    showInHome: true,
  },

  {
    id: uuidv4(),
    icon: "icon-brainstorming",
    img: water_environmental_solutions,
    link_para: "/servicesDetails/environmental_solutions",
    title: "Water & Environmental Solutions",
    desc: "",
    services: [
      "Package STP With MBBR & MBR Technology",
      "Package Reverse Osmosis Water Treatment Plant",
      // "Package Seawater Desalination Plant",
      "Package Effluent Treatment Plant",
      "High Pure Water Treatment System",
      "Grey Water Systems",
      "Treated Sewage Effluent Polishing Plant",
      "De Mineralization Plants",
      "Chemical Dosing Systems",
      // "Waste & Waste Water Treament",
    ],
    servicesImg: [
      // environmental_solutions,
      environmental_solution_02,
      environmental_solution_03,
      environmental_solution_04,
      // environmental_solution_05,
      // water_waste_water_system_01,
      // water_waste_water_system_02,
      water_waste_water_system_03,
      water_waste_water_system_04,
      // water_waste_water_system_05,
      // water_waste_water_system_06,
      // water_waste_water_system_07,
      water_waste_water_system_08,
      water_waste_water_system_09,
      water_waste_water_system_10,
      water_waste_water_system_11,
      water_waste_water_system_12,
      water_waste_water_system_13,
      water_waste_water_system_14,
      environmental_solution_01,
    ],
    servicesDec: [],
    showServicesDec: false,
    showServicesImg: true,
    showInHome: true,
  },

  {
    id: uuidv4(),
    icon: "icon-brainstorming",
    img: hvac_plumbing,
    link_para: "/servicesDetails/hvac_plumbing",
    title: "HVAC & Plumbing",
    desc: "",
    services: [
      "HVAC Valves & Accessories",
      "Plumbing & Drainage Pumps",
      // "Preaction Systems",
      "Floor Drains",
      "Potable Water Valves & Accessories",
    ],
    servicesImg: [
      hvac_01,
      hvac_02,
      hvac_03,
      hvac_04,
      hvac_05,
      // hvac_06,
      plumbing_01,
      plumbing_02,
      plumbing_03,
      hvac_plumbing,
    ],
    servicesDec: [],
    showServicesDec: false,
    showServicesImg: true,
    showInHome: true,
  },
  {
    id: uuidv4(),
    icon: "icon-brainstorming",
    img: passive_fire_protection_01,
    link_para: "/servicesDetails/passive_fire_protection",
    title: "Passive Fire Protection",
    desc: "",
    services: ["Fire & Smoke Curtains", "Fire Stopping & Fire Sealants"],
    servicesImg: [passive_fire_protection_01],
    servicesDec: [],
    showServicesDec: false,
    showServicesImg: true,
    showInHome: true,
  },
];

const third_party_project_documentation_services = [
  {
    id: uuidv4(),
    title: "Tender Support",
    desc: [
      "Pre-Qualification documents, technical proposals, and Bill of Quantities/Bill of Materials (BoQ/BoM)",
      "Expert guidance in system design/selection to meet project requirements",
    ],
  },
  {
    id: uuidv4(),
    title: "Engineering Design: Schematic and Detailed Design",
    desc: [
      "Development of schematic and detailed designs from concept design, in compliance with industry standards and regulations (TIA/IEC/BICSI/NFPA/MOI).",
      "Conducting analytical studies for system efficiency and reliability.",
    ],
  },
  {
    id: uuidv4(),
    title: "Construction Document Support",
    desc: [
      "Preparation of material approval submittals, shop drawings, and Inspection & Test Plans (ITPs).",
      "Comprehensive testing and commissioning procedures to ensure optimal system performance.",
      "BIM / REVIT / AUTOCAD drawings preparation and clash detection.",
    ],
  },
  {
    id: uuidv4(),
    title: "Closeout Documentation",
    desc: [
      "Preparation of Operation & Maintenance (O&M) manuals, as-built drawings, and final project handover documents.",
    ],
  },
  {
    id: uuidv4(),
    title: "EPC of Fire Protection Systems",
    desc: [
      "Design and installation of fire alarm, fire extinguishing, and fire-fighting systems to ensure safety & compliance.",
    ],
  },
  {
    id: uuidv4(),
    title: "Fitout Tasks for ICT/ELV Systems",
    desc: [
      "Complete fitout services ensuring quality installation and seamless system integration.",
    ],
  },
  {
    id: uuidv4(),
    title: "Manpower Supply for Engineering Services",
    desc: [
      "Provide skilled engineers, project managers, and specialists to support project requirements.",
    ],
  },
];

export default servicesHomeData;
