// import React, { useState } from 'react';
// import { send } from '@sendgrid/mail';

// const EmailForm = () => {
//   const [formData, setFormData] = useState({
//     recipient: '',
//     subject: '',
//     message: '',
//   });
//   const [sendStatus, setSendStatus] = useState('idle'); // 'idle', 'sending', 'success', 'error'

//   const handleChange = (event) => {
//     setFormData({ ...formData, [event.target.name]: event.target.value });
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     setSendStatus('sending');

//     try {
//       const { data } = await send({
//         to: 'info@firedocintl.com',
//         from: 'info@firedocintl.com', // Replace with your sender email
//         subject: formData.subject,
//         text: formData.message,
//         apiKey: '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', // Replace with your actual API key
//       });

//       console.log('Email sent successfully:', data);
//       setSendStatus('success');
//     } catch (error) {
//       console.error('Error sending email:', error);
//       setSendStatus('error');
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       {/* Form fields for recipient, subject, message */}
//       <button type="submit" disabled={sendStatus === 'sending'}>
//         {sendStatus === 'sending' ? 'Sending...' : 'Send Email'}
//       </button>
//       {sendStatus === 'success' && <p>Email sent successfully!</p>}
//       {sendStatus === 'error' && <p>Error sending email. Please try again.</p>}
//     </form>
//   );
// };

// export default EmailForm;
