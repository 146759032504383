import { Link } from 'react-router-dom';
// import aboutImg from '../../assets/img/home4/about.jpg';
import aboutImg from '../../assets/images/home/home.webp';

import { useState } from 'react';
import 'react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video';
import CountUp from 'react-countup';
import GeneralInfo from "../GeneralInfo"


const About = () => {
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="EZ9DrY43wtw"
                onClose={() => setOpen(false)}
            />
            <div className="our-agency-about-wrapper section-padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6">
                            <div className="agency-img image-container" >
                                <img src={aboutImg} alt="" />
                            </div>
                        </div>
                        <div className="col-xl-6 ps-xl-5 mt-5 mt-xl-0">
                            <div className="about-agency-content">
                                <div className="section-title-4">
                                    <h2>We strive to be the best in the services we provide.</h2>
                                    <p>Our team boasts vast experience across fire protection, ELV, ICT, HVAC, plumbing, drainage, and environmental engineering. This comprehensive understanding allows us to design and implement systems that seamlessly integrate with your existing infrastructure, ensuring optimal performance and safety.</p>
                                </div>
                                <div className="experience-video-play d-flex">
                                    <div className="year-experience-about d-flex align-items-center">
                                        <div className="year">
                                            <h3><CountUp enableScrollSpy scrollSpyOnce end={GeneralInfo.ExpYearCount} duration={3} />+</h3>
                                        </div>
                                        <div className="text">
                                            <p>Years <br /> Experience</p>
                                        </div>
                                    </div>

                                    <div className="year-experience-about d-flex align-items-center">
                                        <div className="year">
                                            <h3><CountUp enableScrollSpy scrollSpyOnce end={GeneralInfo.projectDoneCount} duration={3} />+</h3>
                                        </div>
                                        <div className="text">
                                            <p>Project <br /> Implemented</p>
                                        </div>
                                    </div>

                                    {/* <div className="video-popup-btn d-flex align-items-center">
                                        <div className="video-play-btn">
                                            <span className="popup-video" onClick={() => setOpen(true)} style={{cursor: 'pointer'}}><i className="fas fa-play"></i></span>
                                            <span>Watch our activities for your
                                            agency growth</span>                     
                                        </div>
                                    </div> */}
                                </div>
                                <div className="checked-features-list theme-color-4">
                                    <ul>
                                        <li>Unmatched Expertise </li>
                                        <li>Customer-Centric Approach</li>
                                        <li>Best-in-Class Products</li>
                                        <li>Quality Installations</li>
                                        <li>Performance Guarantee</li>
                                    </ul>
                                </div>
                                <Link to='/about' className="theme-btn mt-30 style-4">Read More Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About;