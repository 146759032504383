import videoBg from "../../assets/img/video_bg_1.jpg";
import imgBlock1 from "../../assets/images/about/about-01-01.jpg";
import tabImg1 from "../../assets/img/home1/tab-img.jpg";
import { Link } from "react-router-dom";
import { useState } from "react";
import "react-modal-video/scss/modal-video.scss";
import ModalVideo from "react-modal-video";
import OurBusiness from "./OurBusiness";
import ServiceCarousel from "../../Components/Services/ServiceCarousel";
import servicesData from "../../Components/Services/servicesHomeData";

const AboutOne = ({ pt }) => {
  const [isOpen, setOpen] = useState(false);

  console.log(servicesData);

  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="EZ9DrY43wtw"
        onClose={() => setOpen(false)}
      />
      <section
        className={
          pt
            ? "about-section section-padding"
            : "about-section section-padding pt-0"
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-5 col-12">
              <div className="about-header-img">
                <div className="img-block-pop-video">
                  <img src={imgBlock1} alt="busico" />
                  {/* <div className="popup-video-block d-flex justify-content-center align-items-center bg-cover" style={{ backgroundImage: `url(${videoBg})` }}>
                                        <div className="video-play-btn">
                                            <span className="popup-video" onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}><i className="fas fa-play"></i></span>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7 col-md-10 col-12">
            <OurBusiness></OurBusiness>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutOne;
