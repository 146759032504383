import React from 'react'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import bannerBg from '../assets/img/page-banner.jpg';
import PageBanner from '../Components/PageBanner';
import PortfolioOne from "../Components/Portfolio/PortfolioOne";
import PortfolioTwo from "../Components/Portfolio/PortfolioTwo";
import ClientCarousel from "../Components/Brands/ClientCarousel";

const ProjectPage = () => {
  return (
    <div className="font-oswald">
        <Header/>
        <PageBanner title='PORTFOLIO' bannerBg={bannerBg} currentPage='project' />
        <PortfolioOne/>
        <PortfolioTwo/>
        <ClientCarousel/>
        <Footer/>
    </div>
  )
}

export default ProjectPage;